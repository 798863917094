<template>
<div>
    CSV DATA

    <csvdata :items="pot" />
</div>

</template>

<script>

import axios from "axios";
import csvdata from "@/components/campaigns/csvdata";
export default {
    name: "campaign.csvData",
    data(){
        return {
            pot:[
            ]
        }
    },
    created(){
        this.$root.preloader = false;
    },
    mounted(){
        this.getCampaignData();
        this.$root.preloader = false;

    },
    components:{
        csvdata
    },
    methods:{
        getCampaignData(){
            let id = this.$route.query.campaign;
            console.log('c_id: ', id, this.$route.params, this.$route.query);
            let request = {
                c_id:id
            };
            request.timestamp = Date.now();

            console.log('cv request: ', request);
            axios
                .get(`${this.$root.serverUrl}/admin/campaigns/csvData`, {params:request})
                .then((resp) => {

                    console.log('CSV DATA resp data: ', resp.data, resp);
                    // console.log('setSeriesDataDay resp data',  resp.data);
                    if (resp.data && resp.data.length > 0) {
                        let data = resp.data;
                        this.pot = data;
                        console.log('CSV DATA resp data: ', data);
                        // this.$store.commit("setSeriesDataDay", data);
                    }
                });
        }
    }
}
</script>

<style scoped>

</style>
