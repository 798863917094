import { render, staticRenderFns } from "./campaign.csvData.vue?vue&type=template&id=1c9c55da&scoped=true&"
import script from "./campaign.csvData.vue?vue&type=script&lang=js&"
export * from "./campaign.csvData.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c9c55da",
  null
  
)

export default component.exports