<template>
    <div id="app">

        <ejs-grid :dataSource="getData" :allowResizing='true'  :gridLines='lines' :allowFiltering='true' :allowSorting='true' >
            <e-columns>

                <e-column field='data.id' headerText='User ID' textAlign='left' width=75 height=500 class="e-headercell"></e-column>
                <e-column field='data.firstName' headerText='Firstname' width=100></e-column>
                <e-column field='data.lastName' headerText='Lastname' width=110></e-column>
                <e-column field='data.email' headerText='Email' width=110></e-column>
                <e-column field='data.state' headerText='State' width=180></e-column>
                <e-column field='data.match' headerText='Matched' width=180></e-column>
                <e-column field='used' headerText='Used' width=180></e-column>
            </e-columns>
        </ejs-grid>
    </div>
</template>

<script>
import Vue from "vue";
import { GridPlugin, Resize, Sort, Filter } from "@syncfusion/ej2-vue-grids";

Vue.use(GridPlugin);
export default {
    name: "actuals.vue",
    props:['items'],
    data() {
        return {
            lines: 'Both'
        };
    },
    provide: {
        grid: [Resize,Sort, Filter]
    },
    computed: {
        getData() {
            let data = this.items?this.items:[];
            // data = this.$store.state ? this.$store.state.actuals : data;
            return data;
        },
    },
}
</script>

<style scoped>
@import "../../../node_modules/@syncfusion/ej2-base/styles/material.css";
</style>
